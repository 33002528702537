@import "../../App.scss";

$hamburgerColor: white;
$hamburgerWidth: 25px;
$hamburgerHeight: 25px;
$hamburgerIconHeight: 3px;
$hamburgerIconBorderRadius: 10px;
$navbarWidth: 200px;

.header {
  align-items: center;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 1rem;
  position: fixed;
  transition: background-color 500ms ease-in;
  top: 0;
  width: 100%;
  z-index: 999;

  &--scrolled {
    background-color: #c14514;
  }

  &__hamburger {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: $hamburgerHeight;
    justify-content: center;
    width: $hamburgerWidth;
  }

  &__hamburger-icon,
  &__hamburger-icon::before,
  &__hamburger-icon::after {
    border-radius: $hamburgerIconBorderRadius;
    background-color: $hamburgerColor;
    transition: transform 120ms ease-out, background-color 100ms ease-out;
  }

  &__hamburger-icon {
    height: $hamburgerIconHeight;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::before {
      transform: translateY(-250%);
    }

    &::after {
      transform: translateY(250%);
    }
  }

  &__hamburger--active &__hamburger-icon {
    background-color: transparent;
    transform: translateX(50%);
  }

  &__hamburger--active &__hamburger-icon::after {
    transform: translateX(-50%) rotateZ(-45deg);
  }

  &__hamburger--active &__hamburger-icon::before {
    transform: translateX(-50%) rotateZ(45deg);
  }

  &__logo {
    height: 50px;
    padding: 0.6rem 0;
    width: 120px;
    z-index: -1;
  }

  &__nav {
    background-color: #c14514;
    height: 100vh;
    left: 0;
    list-style-type: none;
    padding-top: max(50px, $hamburgerHeight);
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: transform 100ms ease-in;
    width: $navbarWidth;
    z-index: -1;
  }

  &__nav-blur {
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100vw;
  }

  &__hamburger--active ~ &__nav {
    transform: translateX(0);
  }

  &__hamburger--active ~ &__nav-blur {
    display: block;
  }

  &__ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style-type: none;
    padding: 0;
  }

  &__link-logo {
    margin: 0 auto;
    z-index: -1;

    & img {
      display: block;
    }
  }

  &__link {
    display: block;
    color: white;
    padding: 1.2rem 1rem;
    text-decoration: none;
    text-transform: capitalize;

    &--active,
    &:focus-within,
    &:hover {
      background-color: #a72f00;
    }
  }

  @media screen and (min-width: 640px) {
    display: flex;
    flex-direction: column;

    &__hamburger {
      display: none;
    }

    &__nav {
      background-color: transparent;
      display: block;
      height: initial;
      padding-top: 0px;
      position: relative;
      transition: none;
      transform: translateX(0%);
      width: initial;
    }

    &__hamburger--active ~ &__nav-blur {
      display: none;
    }

    &__ul {
      flex-direction: row;
    }

    &__li {
      margin: 0 1rem;
    }

    &__link {
      padding: 0.8rem 1rem;
      position: relative;

      &::before {
        background-color: white;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 200ms ease-out;
        width: 100%;
      }

      &--active,
      &:focus-within,
      &:hover {
        background-color: inherit;
      }

      &--active::before,
      &:focus-within::before,
      &:hover::before {
        transform: scaleX(0.5);
      }
    }
  }
}
