.contact {
  &__image-banner {
    object-fit: cover;
    width: 100%;
  }

  &__card-container {
    align-content: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    margin: 5rem 2rem;
    margin-top: -2.5rem;
    text-align: center;
    text-transform: capitalize;
  }

  &__card {
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 1.5rem;
    width: fit-content;

    h1 {
      font-size: 1rem;
      margin: 0;
    }

    a.contact__phone-number {
      color: blue;
      text-decoration: none;
      letter-spacing: 0.1rem;
    }

    p {
      font-size: 0.9rem;
      margin: 0;
      max-width: 40ch;
    }

    .btn {
      background-color: transparent;
      border: 1px solid #d37a0c;
      border-radius: 10px;
      cursor: pointer;
      font-size: 1.1rem;
      outline: none;
      padding: 0.8em 1.2em;
      text-transform: capitalize;
      transition: all 200ms ease-in;

      a {
        color: #d37a0c;
        text-decoration: none;
      }

      &:focus,
      &:hover {
        background-color: #d37a0c;

        a {
          color: white;
        }
      }
    }
  }

  &__icon {
    color: #262626;
    font-size: 4rem;
  }

  &__map > * {
    height: max(600px, 80vh);
    width: 100%;
  }

  @media screen and (min-width: 640px) {
    &__card-container {
      gap: 5rem;
      margin-bottom: 8rem;
      margin-top: -3rem;
    }

    &__card {
      h1 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    &__card-container {
      gap: 10rem;
      margin-bottom: 10rem;
      margin-top: -5rem;

      p {
        max-width: 50ch;
      }
    }

    &__card {
      min-height: 400px;
      padding: 2.5rem;
    }
  }
}
