.admin {
  &__people-image-client {
    cursor: pointer;
    position: relative;
    height: 100%;

    &::before {
      content: "";
      inset: 0;
      position: absolute;
      transition: background-color 100ms ease-in;
    }

    &:focus-within::before,
    &:hover::before {
      background-color: rgba(0, 0, 0, 0.8);
    }

    & img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &__delete-button {
      align-items: center;
      display: flex;
      inset: 0;
      justify-content: center;
      opacity: 0;
      position: absolute;
      transform: translateY(10px);
      transition: all 200ms ease-in;
    }

    &__delete-button:focus-within,
    &__delete-button:hover {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__people-image-preview {
    object-fit: cover;
    width: 250px;
  }
}
