@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}
