.footer {
  align-items: center;
  background-color: #c14514;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  position: relative;
  text-transform: capitalize;

  &__waves {
    height: 100px;
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
  }

  &__wave {
    background-size: 1000px 100px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &--1 {
      animation: waveToLeft 3s linear infinite;
      opacity: 1;
    }

    &--2 {
      animation: waveToRight 6s linear infinite;
      opacity: 0.5;
    }

    &--3 {
      animation: waveToLeft 4s linear infinite;
      opacity: 0.7;
    }

    &--4 {
      animation: waveToRight 5s linear infinite;
      opacity: 0.3;
    }

    @keyframes waveToLeft {
      0% {
        background-position-x: 1000px;
      }
      100% {
        background-position-x: 0px;
      }
    }

    @keyframes waveToRight {
      0% {
        background-position-x: 0px;
      }
      100% {
        background-position-x: 1000px;
      }
    }
  }

  &__social-icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        color: white;
        display: inline-block;
        font-size: 3em;
        margin: 1rem;
        padding: 0.5rem;
        transition: color 50ms ease-in, transform 200ms ease-in;

        &:hover {
          color: #c0c0c0;
          transform: translateY(-5px);
        }
      }
    }
  }

  &__menu {
    align-items: center;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        color: white;
        display: inline-block;
        font-size: 1.2em;
        margin: 1rem;
        padding: 0.8rem;
        text-decoration: none;

        &:hover {
          color: #c0c0c0;
        }
      }
    }
  }

  &__copyright {
    p {
      color: white;
      text-align: center;
    }
  }
}
