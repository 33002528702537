@import "../../App.scss";

.home {
  overflow: hidden;

  #fixed-whatsapp-icon {
    cursor: pointer;
    position: fixed;
    font-size: 3em;
    bottom: 20px;
    right: 20px;
    z-index: 99;

    a {
      color: #25d366;
    }

    &:not(.active) {
      animation: whatsappBounce 4s infinite;
    }

    @keyframes whatsappBounce {
      20% {
        transform: scale(1, 1) translateY(0);
      }
      30% {
        transform: scale(1.1, 0.9) translateY(0);
      }
      40% {
        transform: scale(0.9, 1.1) translateY(-40px);
      }
      50% {
        transform: scale(1, 1) translateY(0);
      }
      80% {
        transform: scale(1, 1) translateY(0);
      }
    }

    @media screen and (min-width: 640px) {
      font-size: 4em;
      bottom: 30px;
      right: 30px;
    }

    @media screen and (min-width: 1000px) {
      font-size: 5em;
      bottom: 40px;
      right: 40px;
    }
  }

  .btn {
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    padding: 0;
    text-transform: uppercase;

    a {
      color: inherit;
      display: inline-block;
      padding: 0.7em 1.4em;
      text-decoration: none;
    }

    &--black {
      border: 1px solid black;
      color: black;

      &:focus-within,
      &:hover {
        background-color: black;
      }

      &:focus-within a,
      &:hover a {
        color: white;
      }
    }

    &--white {
      border: 1px solid white;
      color: white;

      &:focus-within,
      &:hover {
        background-color: white;
      }

      &:focus-within a,
      &:hover a {
        color: black;
      }
    }

    @media screen and (min-width: 640px) {
      font-size: 1rem;
    }

    @media screen and (min-width: 1000px) {
      font-size: 1.125rem;
    }
  }

  h1 {
    font-weight: 600;
  }

  .btn,
  p {
    line-height: 2rem;
  }
}

.featured {
  background-color: #ee4d2d;
  position: relative;

  &::before {
    background-image: linear-gradient(
      to bottom,
      rgba($color: #000000, $alpha: 0.5),
      transparent,
      transparent
    );
    content: "";
    pointer-events: none;
    position: absolute;
    pointer-events: none;
    inset: 0;
    z-index: 2;
  }
}

.home__swiper--featured {
  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;
  }

  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    background-color: white;
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
  }
}

.home__swiper--trending {
  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;
  }

  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    background-color: black;
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
  }
}

.trending {
  background-image: linear-gradient(to bottom, #ee4d2d, #d6581e);
  padding: 8rem 0;

  h1 {
    color: black;
    font-size: calc(1rem + 2vw);
    font-weight: 500;
    margin-bottom: 6rem;
    text-align: center;
    text-transform: uppercase;
  }

  button {
    display: block;
    margin: 6rem auto 0;
  }

  @media screen and (min-width: 640px) {
    padding: 12rem 0;

    h1 {
      margin-bottom: 10rem;
    }

    button {
      margin: 10rem auto 0;
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 15rem 0;
  }
}

.home__swiper-slide__card {
  background-color: white;
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  width: 80%;

  p {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 1rem 1rem 1.5rem;
    text-align: center;
    text-transform: capitalize;
  }
}

.about {
  background-color: #262626;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 8rem;
  text-transform: capitalize;

  &__image-shadow {
    display: none;
  }

  &__image-featured {
    display: block;
    object-fit: cover;
    width: 100%;
  }

  &__description {
    padding: 3rem;

    h1 {
      color: white;
      font-size: calc(1rem + 2vw);
      margin-bottom: 3rem;
    }

    &-content {
      color: white;
      margin-bottom: 4rem;

      p {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: 640px) {
    padding: 12rem 5rem;

    &__image-container {
      margin: 0 auto;
      position: relative;
      width: 400px;
    }

    &__image-shadow {
      display: block;
      height: 100%;
      left: -30px;
      position: absolute;
      top: 30px;
      width: 100%;
    }

    &__description {
      h1 {
        margin-top: 8rem;
      }
    }
  }

  @media screen and (min-width: 1350px) {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 1fr;
    padding: 15rem 8rem;

    &__image-container {
      width: 600px;
    }

    &__description {
      h1 {
        margin-top: 0;
      }
    }
  }
}

.promotion {
  overflow: hidden;
  position: relative;

  &::before {
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &__image {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  &__description {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 8rem 2rem;
    text-align: center;
    text-transform: capitalize;

    &-content {
      margin-bottom: 3rem;

      h1 {
        font-size: calc(1rem + 2vw);
      }

      p {
        font-size: 1.5rem;
        margin: 0;
      }
    }
  }

  @media screen and (min-width: 640px) {
    &__description {
      padding: 12rem 2rem;
    }
  }

  @media screen and (min-width: 1000px) {
    &__description {
      padding: 15rem 2rem;
    }
  }
}

.privilege {
  align-items: flex-end;
  background-image: linear-gradient(to bottom, #ee4d2d, #d6581e);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 8rem 2rem;

  h1 {
    color: black;
    font-size: calc(1rem + 2vw);
    text-align: center;
    text-transform: capitalize;
    width: 100%;
  }

  h2 {
    color: black;
    font-size: calc(1rem + 1vw);
    text-align: center;
    text-transform: capitalize;
  }

  p {
    color: black;
    text-align: center;
    text-transform: capitalize;
  }

  &__item {
    text-align: center;
  }

  &__certification-container {
    position: relative;
  }

  &__certification {
    height: 230px;
    width: 230px;
  }

  &__certification-icon {
    bottom: 0;
    color: green;
    font-size: 30px;
    position: absolute;
    right: 0;
  }

  &__icon {
    color: #313e5d;
    font-size: 8rem;
  }

  @media screen and (min-width: 640px) {
    gap: 8rem;
    padding: 12rem 2rem;
  }

  @media screen and (min-width: 1000px) {
    padding: 15rem 2rem;
  }
}

.people {
  background-color: #262626;
  padding: 8rem 2rem;
  position: relative;

  &__client {
    h1 {
      color: white;
      font-size: calc(1rem + 2vw);
      font-weight: normal;
      margin-bottom: 5rem;
      text-align: center;
      text-transform: capitalize;
    }
  }

  &__swiper {
    $border-radius: 10px;

    height: 250px;
    margin: 3rem auto;
    position: relative;
    width: 170px;

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
      background-color: white;
    }

    img {
      border-radius: $border-radius;
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__owner {
    h1 {
      color: white;
      font-size: calc(1rem + 2vw);
      font-weight: normal;
      margin-top: 10rem;
      text-align: center;
      text-transform: capitalize;
    }

    h2 {
      color: white;
      font-size: calc(1rem + 0.5vw);
      font-weight: normal;
      margin: 5rem 0;
      text-align: center;
      text-transform: capitalize;
    }

    &-detail {
      color: white;
      display: flex;
      flex-wrap: wrap;
      gap: 6rem;
      justify-content: center;

      img {
        border-radius: 10px;
        box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.4);
        display: block;
        height: 250px;
        margin: 0 auto;
        object-fit: cover;
        width: 170px;
      }

      p {
        margin: 0;
        text-align: center;
        text-transform: capitalize;
      }
    }
  }

  @media screen and (min-width: 640px) {
    padding: 12rem 4rem;

    &__swiper {
      height: 350px;
      width: 250px;
    }

    &__owner {
      &-detail {
        img {
          height: 350px;
          width: 250px;
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    padding: 15rem 4rem;

    & {
      display: grid;
      align-items: flex-end;
      justify-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 5rem;
    }

    &__swiper {
      height: 500px;
      margin: 0 auto;
      width: 350px;
    }

    &__owner {
      &-detail {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;

        img {
          height: 500px;
          width: 350px;
        }

        p {
          text-align: left;
          max-width: 70ch;
        }
      }
    }
  }
}

.invitation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max(100vh, 600px);
  overflow: hidden;
  position: relative;

  &::before {
    background-color: rgba($color: #000000, $alpha: 0.8);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &__video {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  &__description {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 10%;
    text-align: center;
    text-transform: capitalize;

    p {
      line-height: calc(2rem + 2vw);
      margin: 0;
    }

    &-content {
      font-size: calc(1rem + 2vw);
      font-weight: 600;
      margin-bottom: 3rem;
    }
  }
}
