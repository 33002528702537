.catalog {
  position: relative;
  text-transform: capitalize;

  .scroll-to-top {
    align-items: center;
    display: flex;
    background-color: #ee4d2d;
    border-radius: 100%;
    bottom: 20px;
    color: white;
    cursor: pointer;
    font-size: 1.5em;
    height: 40px;
    justify-content: center;
    position: fixed;
    right: 20px;
    width: 40px;
    z-index: 99;
  }

  &__image-banner {
    display: block;
    object-fit: cover;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 1.5rem;
    position: relative;
  }

  &__filter-container {
    max-width: 280px;
    margin: 0 auto;
  }

  &__filter-box {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
    padding: 1.5rem;

    h1 {
      margin: 0;
    }
  }

  &__product {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
    overflow: hidden;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
    }

    &-description {
      padding: 1.5rem;
    }

    &-header {
      p {
        margin: 0;
      }
    }

    &-body {
      h3 {
        color: gray;
        font-size: 0.8rem;
        margin: 0.8rem 0;
      }
    }

    &-color-container,
    &-size-container {
      display: flex;
      flex-wrap: wrap;
    }

    &-color {
      border: 1px solid black;
      height: 20px;
      width: 20px;
    }

    &-size-container {
      gap: 0.5rem;
    }

    &-size {
      border: 1px solid rgba($color: #000000, $alpha: 0.3);
      padding: 0.2em 0.7em;
    }
  }

  @media screen and (min-width: 700px) {
    &__content {
      flex-direction: row;
    }

    &__filter-container {
      height: fit-content;
      margin: 0;
      position: sticky;
      top: calc(100px + 1.5rem);
    }

    &__filter-grid {
      height: calc(100vh - 100px - 4rem);
      overflow: auto;
      position: relative;
    }

    &__filter-title {
      background-color: white;
      position: sticky;
      top: -1rem;
      z-index: 99;
    }

    &__product-container {
      flex: 1;
      min-height: 200vh;
    }
  }
}
